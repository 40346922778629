import { useQuery } from "@tanstack/react-query";
import { RollingPositionAllocationResponseModel } from "@tradeblotter/tradeblotter-api";

import { usePortfolioStatsAll } from "../../../../hooks/usePortfolioStats";
import { useGlobalStore } from "../../../../store/global";

type TDataType = RollingPositionAllocationResponseModel["data"];
// type TPortfolio = keyof TDataType;
// type TSymbol = keyof TDataType[TPortfolio];
// type TPortfolioData = TDataType[TPortfolio];
// type TDateAndValueArrayType = TDataType[TPortfolio][TSymbol];

// @TODO: fix type, from API it's object but should use another one
const transformAllocationHistoricResponse = (
    portfolio: string,
    rollingPositionAllocation?: TDataType
): { data: ApexAxisChartSeries; categories: string[] } => {
    if (!rollingPositionAllocation) {
        return { data: [], categories: [] };
    }

    const symbolKeys = Object.keys(rollingPositionAllocation);
    if (symbolKeys.length < 1) return { data: [], categories: [] };

    const categories = symbolKeys;
    const data = symbolKeys.map((item) => {
        const portfolioData = rollingPositionAllocation[item]?.[portfolio];
        const values = portfolioData ? Object.values(portfolioData) : [];

        return { name: item, data: values };
    });

    const filteredData = data.filter((item) => item.data.length > 0);

    // Parse the filtered data
    const parsedData = filteredData.map((item) => {
        const result = {
            name: item.name,
            data: item.data.map((value) => {
                return {
                    x: value?.d ?? 0,
                    y: isNaN(Number(value?.v)) ? 0 : value.v,
                };
            }),
        };
        return result;
    });

    const filteredCategories = categories.filter(
        (category, index) => data[index].data.length > 0
    );

    return { data: parsedData, categories: filteredCategories };
};

export const useTransformHistoricAllocation = () => {
    const rawDataQuery = usePortfolioStatsAll(); // Assume this returns a query object
    const selectedPortfolio = useGlobalStore(
        (state) => state.selectedAccount
    ).id;
    return useQuery({
        queryKey: [
            "transformed",
            {
                type: "HistoricAllocationData",
                selectedPortfolio,
                dataUpdatedAt: rawDataQuery.dataUpdatedAt,
            },
        ],
        queryFn: async () => {
            if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
                return null;
            }

            return transformAllocationHistoricResponse(
                selectedPortfolio,
                rawDataQuery.data.results.rollingPositionAllocation?.data
            );
        },
        enabled: rawDataQuery.isSuccess,
        staleTime: Infinity,
    });
};
