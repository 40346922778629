import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import LineChart from "../../Charts/LineChart";
import SkeletonVerticalChart from "../../Charts/placeholders/SkeletonVerticalChart";
import { useTransformHistoricAllocation } from "./hooks/useTransformHistoricAllocation";

const HistoricAllocation = (props: { id: string }) => {
    const { isLoading: isLoadingPortfolioStats } = usePortfolioStatsAll();
    const { data: transformedData, isLoading } =
        useTransformHistoricAllocation();

    if (isLoading || isLoadingPortfolioStats) {
        return <SkeletonVerticalChart />;
    }
    return (
        <LineChart
            data={transformedData?.data || []}
            categories={transformedData?.categories || []}
            options={{
                chart: {
                    id: props.id,
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    type: "datetime",
                },
            }}
        />
    );
};

export default HistoricAllocation;
