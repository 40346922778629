import * as React from "react";

import { AuthProvider } from "../auth/AuthProvider";

import { Main } from "./Main";

export const MainWithAuth: React.FC = () => {
    return (
        <AuthProvider>
            <Main />
        </AuthProvider>
    );
};
