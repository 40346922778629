import { useQuery } from "@tanstack/react-query";
import {
    AccountResponseModel,
    TopHoldingsResponseModel,
} from "@tradeblotter/tradeblotter-api";

import { usePortfolioStatsAll } from "../../../../hooks/usePortfolioStats";
import { useCallback } from "react";
import { useMyAccounts } from "../../../../hooks/useMyAccounts";

type TAllocationDataType = TopHoldingsResponseModel["data"];

const transformAllocationData = (
    data: TAllocationDataType | undefined,
    myAccounts: AccountResponseModel[],
    selectedPortfolioId: string
) => {
    if (!data) return { transformedData: [], symbols: [] };

    const symbols = Object.keys(data);

    let allAccountIds = getAccountIds(data);

    if (selectedPortfolioId !== "portfolio") {
        allAccountIds = allAccountIds.filter((accountId) => {
            return accountId.includes(selectedPortfolioId);
        });
    }

    const transformedData = allAccountIds.map((accountId) => {
        const accountData = symbols.map((symbol) => {
            // @ts-expect-error
            const value = data[symbol][accountId];
            return value ? Number(value) : 0;
        });
        const selectedAccountAlias = myAccounts.find(
            (account) => account.id === accountId
        )?.accountAlias;
        return { name: selectedAccountAlias, data: accountData };
    });

    return { transformedData, symbols };
};

export const useTransformAllocationData = (selectedPortfolioId: string) => {
    const rawDataQuery = usePortfolioStatsAll();
    const myAccounts = useMyAccounts();
    const queryFn = useCallback(async () => {
        if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
            throw new Error("Raw data is not available");
        }

        return transformAllocationData(
            rawDataQuery?.data?.results?.topHoldings?.data,
            myAccounts.data?.results || [],
            selectedPortfolioId
        );
    }, [rawDataQuery, myAccounts, selectedPortfolioId]);

    return useQuery({
        queryKey: [
            "transformed",
            {
                type: "AllocationData",
                updatedAt: rawDataQuery.dataUpdatedAt,
                selectedPortfolioId,
            },
        ],
        queryFn,
        enabled: rawDataQuery.isSuccess,
        staleTime: Infinity,
    });
};

const getAccountIds = (data: TAllocationDataType | undefined) => {
    if (!data) return [];
    const accountIds = Object.values(data).flatMap((tickerData) =>
        Object.keys(tickerData).filter((key) => key !== "portfolio")
    );
    return [...new Set(accountIds)]; // Ensures unique accountIds
};
