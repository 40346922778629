export const transformPortfolioValueToMonthDataSeries = (
    data: Array<{
        d: string;
        v: string;
    }>
) => {
    const v = data.map((item) => {
        const percentage = parseFloat(item.v);
        return isNaN(percentage)
            ? [new Date(item?.d).getTime(), 0]
            : [new Date(item?.d).getTime(), percentage];
    });

    return {
        data: {
            x: [data[0]?.d],
            y: [{ name: "Portfolio value", data: v }],
        },
    };
};
