import { Section } from "@blueprintjs/core";
import Drawdown from "./DrawDown";
import TooltipWithIcon from "../../TooltipWithIcon";

// import DateSelector from "../../DateSelector";

const DrawdownContainer = (props: { id: string }) => {
    return (
        <Section
            title={<TooltipWithIcon title="Portfolio drawdown" tip="..." />}
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <Drawdown id={props.id} />
        </Section>
    );
};

export default DrawdownContainer;
