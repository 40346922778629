import { enabledChartAnimation } from "../../config";
import { usePortfolioStatsAll } from "../../hooks/usePortfolioStats";
import SparkLinesChart from "../Charts/SparkLinesChart";
import { useTransformTotalReturn } from "./hooks/useTransformTotalReturn";
import { SkeletonVerticalChartForCard } from "../Skeletons/SkeletonCard";

const TotalReturnChart = () => {
    const { isLoading } = usePortfolioStatsAll();
    const { data: transformedTotalReturnData, isError } =
        useTransformTotalReturn();

    if (isLoading) {
        return <SkeletonVerticalChartForCard />;
    }

    if (isError) {
        return <div>Error</div>;
    }
    return (
        <SparkLinesChart
            categories={transformedTotalReturnData?.data.x || []}
            data={transformedTotalReturnData?.data.y || []}
            options={{
                chart: {
                    id: "total-return-chart-dashboard",
                    animations: {
                        enabled: enabledChartAnimation,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                noData: {
                    text: isLoading ? "Loading..." : "No data available",
                },
            }}
        />
    );
};

export default TotalReturnChart;
