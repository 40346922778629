import * as React from "react";

import { useAuth } from "../auth/useAuth";
import MainLoggedInLayout from "./MainLoggedInLayout";
import { LoadingSpinner } from "../components/LoadingSpinner";

export const MainLoggedOut: React.FC = () => {
    return (
        <>
            <div className="mx-auto max-w-sm justify-center items-center h-full flex">
                <div className="w-full" id="login-embed"></div>
            </div>
        </>
    );
};

export const Main: React.FC = () => {
    const { isLoading, user } = useAuth();
    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (user) {
        return <MainLoggedInLayout />;
    }

    return <MainLoggedOut />;
};
