import { usePortfolioStatsAll } from "../../hooks/usePortfolioStats";
import { currencyFormatter } from "../../utils/formatters";
import SparkLinesChart from "../Charts/SparkLinesChart";
import { SkeletonVerticalChartForCard } from "../Skeletons/SkeletonCard";
import { useTransformPortfolioValueToMonth } from "./hooks/useTransformPortfolioValueToMonth";

const PortfolioValueChart = () => {
    const { isLoading } = usePortfolioStatsAll();
    const { data: transformedPortfolioValueData, isError } =
        useTransformPortfolioValueToMonth();

    if (isLoading) {
        return <SkeletonVerticalChartForCard />;
    }

    if (isError) {
        return <div>Error</div>;
    }

    return (
        <SparkLinesChart
            categories={transformedPortfolioValueData?.data.x || []}
            data={transformedPortfolioValueData?.data.y || []}
            options={{
                chart: {
                    id: "portfolio-value-chart-dashboard",
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                yaxis: {
                    decimalsInFloat: 2,
                    labels: {
                        formatter: (value: number) => {
                            return currencyFormatter(value, {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                            });
                        },
                    },
                },
                noData: {
                    text: isLoading ? "Loading..." : "No data available",
                },
            }}
        />
    );
};

export default PortfolioValueChart;
