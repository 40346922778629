import { ItemRenderer, Suggest } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { useMyAccounts } from "../../hooks/useMyAccounts";
import { useImportStore } from "../ImportDialog/store/import-store";
import { getAccounts } from "../../api/accounts/id";
import { toaster } from "../../utils/customToasterSetup";
import {
    AccountResponseModel,
    GetAccounts200Response,
} from "@tradeblotter/tradeblotter-api";
import { apiClient } from "../../api/client";

const renderItem: ItemRenderer<GetAccounts200Response["results"][0]> = (
    account,
    { handleClick, modifiers, query }
) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            active={modifiers.active}
            roleStructure="listoption"
            key={account.id}
            label={account.accountAlias}
            onClick={handleClick}
            text={highlightText(account.accountAlias, query)}
        />
    );
};

const highlightText = (text: string, query: string) => {
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return (
        <>
            {parts.map((part, index) =>
                part.toLowerCase() === query.toLowerCase() ? (
                    <strong key={index}>{part}</strong>
                ) : (
                    part
                )
            )}
        </>
    );
};

const handleCreateAccount = async ({
    query,
    queryClient,
}: {
    query: string;
    queryClient: QueryClient;
}) => {
    try {
        const response = await apiClient.api.createAccount({
            accountAlias: query,
            benchmarkSymbol: "SPY",
            description: "",
            capitalBase: "0",
        });
        if (response.results.id) {
            await queryClient.invalidateQueries({ queryKey: ["accounts"] });
        }
        return response.results;
    } catch (error) {
        console.error("Error creating account:", error);
        toaster.show({
            message: "Error creating account",
            intent: "danger",
        });
    }
};

interface ISuggestPortfolioProps {
    selectedPortfolio: string;
    setValue: ({ accountAlias, brokerageId }: AccountResponseModel) => void;
    enableAllSelection?: boolean;
}

const SuggestPortfolio = ({
    setValue,
    selectedPortfolio,
    enableAllSelection = false,
}: ISuggestPortfolioProps) => {
    const { data: accounts, isLoading } = useMyAccounts();
    const queryClient = useQueryClient();
    const transactionsConfig = useImportStore(
        (state) => state.transactionsConfig
    );

    const updateTransactionsConfig = useImportStore(
        (state) => state.updateTransactionsConfig
    );
    const overrideHandleClick = (
        item: AccountResponseModel,
        handleClick: (item: AccountResponseModel) => void
    ) => {
        setValue(item);
        handleClick(item);
        updateTransactionsConfig({
            ...transactionsConfig,
            accountId: item.id,
            accountAlias: item.accountAlias,
            brokerageId: item.brokerageId,
        });
    };

    const allSelectionItem: AccountResponseModel = {
        accountAlias: "All",
        id: "portfolio",
        brokerageId: "",
        capitalBase: 0,
        description: "",
        benchmarkSymbol: "SPY",
    };

    const items = enableAllSelection
        ? [allSelectionItem].concat(accounts?.results || [])
        : accounts?.results || [];
    return (
        <Suggest<AccountResponseModel>
            disabled={isLoading}
            items={items}
            itemRenderer={(
                item,
                { handleClick: rawHandleClick, modifiers, query, index }
            ) => {
                return renderItem(item, {
                    handleClick: (e) => {
                        e.preventDefault();
                        overrideHandleClick(item, () => rawHandleClick(e));
                    },
                    modifiers,
                    query,
                    index,
                });
            }}
            defaultSelectedItem={accounts?.results?.find((account) => {
                return account.accountAlias === selectedPortfolio;
            })}
            inputValueRenderer={(item) => item.accountAlias}
            createNewItemPosition={"first"}
            createNewItemFromQuery={(query): AccountResponseModel => ({
                accountAlias: query,
                id: query,
                capitalBase: 0,
                brokerageId: query,
                benchmarkSymbol: "SPY",
                description: "",
            })}
            createNewItemRenderer={(
                query,
                active,
                handleClick
            ): React.ReactElement => {
                return (
                    <MenuItem
                        text={`Add new: "${query}"`}
                        icon="plus"
                        active={active}
                        onClick={async (e) => {
                            e.preventDefault();
                            const createdAccount = await handleCreateAccount({
                                query,
                                queryClient,
                            });
                            if (!createdAccount) return;
                            const accounts = await getAccounts();
                            const createAccount = accounts?.results?.find(
                                (account) => account.id === createdAccount.id
                            );
                            if (!createAccount) return;
                            setValue(createAccount);

                            updateTransactionsConfig({
                                ...transactionsConfig,
                                accountId: createdAccount.id,
                                accountAlias: createdAccount?.accountAlias,
                                brokerageId: createdAccount?.brokerageId,
                            });
                            handleClick(e);
                        }}
                    />
                );
            }}
            noResults={
                <MenuItem disabled={true} text="No matching portfolios." />
            }
        />
    );
};

export default SuggestPortfolio;
