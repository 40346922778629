import { format } from "date-fns";
import { toDate, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const resolveTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const localToUtcDatetime = (date: Date) => {
  return zonedTimeToUtc(date, resolveTimeZone());
};

export const utcToLocalDatetime = (date: Date) => {
  return utcToZonedTime(date, resolveTimeZone());
};

export const formattedForDateInput = (date: Date) => {
  // Date inputs always expect and return local time
  return format(toDate(date, { timeZone: resolveTimeZone() }), "yyyy-MM-dd'T'HH:mm");
};

export const assumeUTC = (date: Date | string | number) => {
  return toDate(date, { timeZone: "UTC" });
};
