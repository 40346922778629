import Chart from "react-apexcharts";
import _ from "lodash";

interface IHorizontalStackedBarChartProps {
    data: ApexCharts.ApexOptions["series"];
    categories?: string[];
    height?: number;
    options?: ApexCharts.ApexOptions;
}

const HorizontalStackedBarChart = (props: IHorizontalStackedBarChartProps) => {
    const { data, categories = [], height = 400, options } = props;

    const baseOptions: ApexCharts.ApexOptions = {
        chart: {
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: false,
                    },
                },
            },
        },
        stroke: {
            curve: "straight",
            width: 1,
        },
        grid: {
            show: true,
            borderColor: "#585d63",
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: ["#f4f5f7"],
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: ["#f4f5f7"],
                },
            },
        },
        tooltip: {
            theme: "dark",
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "bottom",
            horizontalAlign: "left",
            offsetX: 40,
            labels: {
                colors: "#f4f5f7",
            },
        },
        noData: {
            text: "No data available",
            style: {
                fontSize: "14px",
                color: "#ffffff",
            },
        },
    };

    if (categories?.length && baseOptions.xaxis?.categories) {
        baseOptions.xaxis.categories = categories;
    }
    if (height && baseOptions.chart?.height) {
        baseOptions.chart.height = height;
    }

    return (
        <Chart
            options={_.merge(
                {
                    ...baseOptions,
                    chart: { ...baseOptions.chart, stacked: true },
                },
                options
            )}
            series={data}
            type="bar"
            height={height}
        />
    );
};

export default HorizontalStackedBarChart;
