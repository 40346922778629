import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { useQuery } from "@tanstack/react-query";
import { transformPortfolioToMonthDataSeries } from "../../../data-transformers/portfolio-to-month-series-data";
import { useCallback } from "react";
import { useGlobalStore } from "../../../store/global";

export const useTransformTotalReturn = () => {
    const rawDataQuery = usePortfolioStatsAll();
    const selectedPortfolio = useGlobalStore(
        (state) => state.selectedAccount
    ).id;
    const queryFn = useCallback(async () => {
        if (!rawDataQuery.isSuccess || !rawDataQuery.data) {
            throw new Error("Raw data is not available");
        }

        return transformPortfolioToMonthDataSeries(
            rawDataQuery.data.results.cumReturns?.data[selectedPortfolio] || []
        );
    }, [rawDataQuery, selectedPortfolio]);
    return useQuery({
        queryKey: [
            "transformed",
            {
                type: "TotalReturnData",
                selectedPortfolio,
                dataUpdatedAt: rawDataQuery.dataUpdatedAt,
            },
        ],
        queryFn,
        enabled: rawDataQuery.isSuccess,
        staleTime: Infinity,
    });
};
