import React from 'react';
import { Tooltip, Icon } from "@blueprintjs/core";

interface TooltipWithIconProps {
    title: string;
    tip: string;
}

const TooltipWithIcon: React.FC<TooltipWithIconProps> = ({ title, tip }) => {
    return (
        <span>
            {title}{" "}
            <Tooltip content={<span>{tip}</span>}>
                <Icon size={14} icon="info-sign" />
            </Tooltip>
        </span>
    );
};

export default TooltipWithIcon;
