import { usePortfolioStatsAll } from "../../hooks/usePortfolioStats";
import SparkLinesChart from "../Charts/SparkLinesChart";

import { useTransformSharpeRatio } from "./hooks/useTransformSharpeRatio";
import { SkeletonVerticalChartForCard } from "../Skeletons/SkeletonCard";

const SharpeRatioChart = () => {
    const { isLoading } = usePortfolioStatsAll();
    const { data: transformedSharpeRatioData, isError } =
        useTransformSharpeRatio();

    if (isLoading) {
        return <SkeletonVerticalChartForCard />;
    }

    if (isError) {
        return <div>Error</div>;
    }

    return (
        <SparkLinesChart
            categories={transformedSharpeRatioData?.data.x || []}
            data={transformedSharpeRatioData?.data.y || []}
            options={{
                chart: {
                    id: "sharpe-ratio-chart-dashboard",
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                noData: {
                    text: isLoading ? "Loading..." : "No data available",
                },
            }}
        />
    );
};

export default SharpeRatioChart;
