export const transformPortfolioToMonthDataSeries = (
    data: Array<{
        d: string;
        cr: string;
        bpr: string;
    }>
) => {
    if (data.length < 1) return { data: { x: [], y: [] } };
    const cr: [number, number][] = data.map((item) => {
        const percentage = parseFloat(item.cr);
        return isNaN(percentage)
            ? [new Date(item.d).getTime(), 0]
            : [new Date(item.d).getTime(), percentage];
    });
    const bpr: [number, number][] = data.map((item) => {
        const percentage = parseFloat(item.bpr);
        return isNaN(percentage)
            ? [new Date(item.d).getTime(), 0]
            : [new Date(item.d).getTime(), percentage];
    });

    return {
        data: {
            x: [data[0].d],
            y: [
                { name: "Portfolio", data: cr },
                { name: "Benchmark", data: bpr },
            ],
        },
    };
};
