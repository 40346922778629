import { create } from "zustand";
import type { TransactionImportResponseModel } from "@tradeblotter/tradeblotter-api";

export const TICKER = "ticker";
export const TRADE_DATE = "trade date";
export const BUY_SELL = "buy sell";
export const QUANTITY = "quantity";
export const PRICE = "price";
export const COMMISSION = "commission";

export const transactionsMappingDefaultValues: IFormDataImport["transactionsMapping"] =
    {
        [TICKER]: {
            selectedIndex: -1,
            label: "Ticker",
            backendId: "symbol",
            id: TICKER,
        },
        [TRADE_DATE]: {
            selectedIndex: -1,
            label: "Trade date",
            backendId: "date/time",
            id: TRADE_DATE,
        },
        [BUY_SELL]: {
            selectedIndex: -1,
            label: "Buy/Sell",
            backendId: "buy/sell",
            id: BUY_SELL,
        },
        [QUANTITY]: {
            selectedIndex: -1,
            label: "Quantity",
            backendId: "quantity",
            id: QUANTITY,
        },
        [PRICE]: {
            selectedIndex: -1,
            label: "Price",
            backendId: "price",
            id: PRICE,
        },
        [COMMISSION]: {
            selectedIndex: -1,
            label: "Commission",
            backendId: "commission",
            id: COMMISSION,
        },
    };

export const KEY_WORDS_MAPPING = {
    symbol: TICKER,
    ticker: TICKER,
    "ticker symbol": TICKER,
    date: TRADE_DATE,
    "trade date": TRADE_DATE,
    datetime: TRADE_DATE,
    time: TRADE_DATE,
    "trade datetime": TRADE_DATE,
    "buy/sell": BUY_SELL,
    "buy or sell": BUY_SELL,
    "trade type": BUY_SELL,
    action: BUY_SELL,
    side: BUY_SELL,
    quantity: QUANTITY,
    share: QUANTITY,
    amount: QUANTITY,
    shares: QUANTITY,
    "share amount": QUANTITY,
    number: QUANTITY,
    price: PRICE,
    "trade price": PRICE,
    "execution price": PRICE,
    commission: COMMISSION,
} as const;

export interface IMapField {
    selectedIndex: number;
    backendId: string;
    label: string;
    id: string;
}

export interface IMapFields {
    [TICKER]: IMapField;
    [TRADE_DATE]: IMapField;
    [BUY_SELL]: IMapField;
    [QUANTITY]: IMapField;
    [PRICE]: IMapField;
    [COMMISSION]: IMapField;
}

export interface IFormDataImport {
    importType: {
        selected: string;
    };
    transactionsConfig: {
        accountId: string;
        brokerageId: string;
        accountAlias: string;
        capitalBase: number | string;
        file: File | null;
        orderedData?: string[][] | null;
    };
    transactionsMapping: IMapFields;
    confirm: {
        status: "pending" | "success" | "error";
        receivedData: TransactionImportResponseModel | null;
    };
    process: {
        status: "pending" | "success" | "error" | null;
        receivedData: TransactionImportResponseModel | null;
    };
}

interface IImportStoreState {
    selectedImportType: string;
    updateSelectedImportType: (selectedImportType: string) => void;
    transactionsConfig: IFormDataImport["transactionsConfig"];
    updateTransactionsConfig: (
        transactionsConfig: IFormDataImport["transactionsConfig"]
    ) => void;
    updateTransactionsConfigAccountAlias: (accountAlias: string) => void;
    transactionsMapping: IFormDataImport["transactionsMapping"];
    updateTransactionsMapping: (
        transactionsMapping: IFormDataImport["transactionsMapping"]
    ) => void;
    mappingFieldsFromFile: string[][];
    updateMappingFieldsFromFile: (mappingFieldsFromFile: string[][]) => void;
    confirm: IFormDataImport["confirm"];
    updateConfirmStatus: (status: "pending" | "success" | "error") => void;
    updateConfirmData: (
        receivedData: TransactionImportResponseModel | null
    ) => void;
    process: IFormDataImport["process"];
    updateProcessStatus: (
        status: "pending" | "success" | "error" | null
    ) => void;
    updateProcessData: (
        receivedData: TransactionImportResponseModel | null
    ) => void;
    resetStore: () => void;
}

export const useImportStore = create<IImportStoreState>((set) => ({
    selectedImportType: "",
    updateSelectedImportType: (selectedImportType: string) =>
        set({ selectedImportType }),
    transactionsConfig: {
        accountId: "",
        brokerageId: "",
        accountAlias: "",
        capitalBase: 0,
        file: null,
        orderedData: null,
    },
    updateTransactionsConfig: (
        transactionsConfig: IFormDataImport["transactionsConfig"]
    ) => set({ transactionsConfig }),
    updateTransactionsConfigAccountAlias: (accountAlias: string) =>
        set((state) => ({
            transactionsConfig: {
                ...state.transactionsConfig,
                accountAlias,
            },
        })),
    transactionsMapping: transactionsMappingDefaultValues,
    updateTransactionsMapping: (
        transactionsMapping: IFormDataImport["transactionsMapping"]
    ) => set({ transactionsMapping }),
    mappingFieldsFromFile: [],
    updateMappingFieldsFromFile: (mappingFieldsFromFile: string[][]) =>
        set({ mappingFieldsFromFile }),
    confirm: {
        status: "pending",
        receivedData: null,
    },
    updateConfirmStatus: (status: "pending" | "success" | "error") =>
        set((state) => ({ confirm: { ...state.confirm, status } })),
    updateConfirmData: (receivedData: TransactionImportResponseModel | null) =>
        set((state) => ({ confirm: { ...state.confirm, receivedData } })),
    process: {
        status: null,
        receivedData: null,
    },
    updateProcessStatus: (status: "pending" | "success" | "error" | null) =>
        set((state) => ({ process: { ...state.process, status } })),
    updateProcessData: (receivedData: TransactionImportResponseModel | null) =>
        set((state) => ({ process: { ...state.process, receivedData } })),
    resetStore: () =>
        set({
            selectedImportType: "",
            transactionsConfig: {
                accountId: "",
                brokerageId: "",
                accountAlias: "",
                capitalBase: 0,
                file: null,
                orderedData: null,
            },
            transactionsMapping: transactionsMappingDefaultValues,
            mappingFieldsFromFile: [],
            confirm: {
                status: "pending",
                receivedData: null,
            },
            process: {
                status: null,
                receivedData: null,
            },
        }),
}));
