import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import AreaChart from "../../Charts/AreaChart";
import SkeletonVerticalChart from "../../Charts/placeholders/SkeletonVerticalChart";
import { useTransformDrawdownToMonthData } from "./hooks/useTransformDrawdownToMonthData";

const Drawdown = (props: { id: string }) => {
    const { isLoading: isLoadingPortfolioStats } = usePortfolioStatsAll();
    const { data: transformedData } = useTransformDrawdownToMonthData();

    return (
        <div>
            {isLoadingPortfolioStats ? (
                <SkeletonVerticalChart />
            ) : (
                <AreaChart
                    categories={transformedData?.data.x || []}
                    data={transformedData?.data.y || []}
                    options={{
                        chart: {
                            id: props.id,
                            animations: {
                                enabled: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                        },
                        xaxis: {
                            type: "datetime",
                        },
                        yaxis: {
                            labels: {
                                formatter: (value) =>
                                    `${(value * 100).toFixed(0)}%`,
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Drawdown;
