import { Section } from "@blueprintjs/core";
import TooltipWithIcon from "../components/TooltipWithIcon";
import DateSelector from "../components/DateSelector";
import Volatility from "../components/widgets/Volatility/Volatility";

const RollingRisk = () => {
    return (
        <Section
            title={<TooltipWithIcon title="Rolling volatility" tip="..." />}
            rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <Volatility id={"rolling-volatility-rolling-risk-page-chart"} />
        </Section>
    );
};

export default RollingRisk;
