import _ from "lodash";
import Chart from "react-apexcharts";

interface ISparkBarChartProps {
    data: ApexCharts.ApexOptions["series"];
    options?: ApexCharts.ApexOptions;
    categories?: string[];
    height?: number;
}

const SparkBarChart = (props: ISparkBarChartProps) => {
    const { data, categories = [], options } = props;

    const baseOptions: ApexCharts.ApexOptions = {
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: 2,
        },
        fill: {
            opacity: 0.3,
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: "#f4f5f7",
                },
            },
            axisBorder: {
                show: true,
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
        },
        tooltip: {
            theme: "dark",
        },
        title: {
            text: undefined,
        },
        noData: {
            text: "No data available",
            style: {
                fontSize: "12px",
                color: "#ffffff",
            },
        },
    };

    if (categories?.length && baseOptions.xaxis?.categories) {
        baseOptions.xaxis.categories = categories;
    }

    return (
        <Chart
            options={_.merge(baseOptions, options)}
            series={data}
            type="bar"
        />
    );
};

export default SparkBarChart;
