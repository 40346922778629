import { Configuration, DefaultApi } from "@tradeblotter/tradeblotter-api";
import { getOutsetaToken } from "../utils";
import { config } from "../config";

class APIClient {
    public api: DefaultApi;

    constructor() {
        this.api = new DefaultApi(
            new Configuration({
                basePath: config.API_BASE_URL,
                middleware: [
                    {
                        pre: async (context) => {
                            const authHeader = {
                                Authorization: `Bearer ${getOutsetaToken()}`,
                            };
                            context.init.headers = {
                                ...context.init.headers,
                                ...authHeader,
                            };
                        },
                        post: async (context) => {
                            const response = await context.response
                                .text()
                                .then((response) =>
                                    JSON.parse(
                                        response.replace(/\bNaN\b/g, "null")
                                    )
                                )
                                .then((response) => {
                                    // If duplicated, failed, or successful === null, return empty array
                                    if (
                                        response?.results?.summary
                                            ?.duplicated === null
                                    )
                                        response.results.summary.duplicated =
                                            [];
                                    if (
                                        response?.results?.summary?.failed ===
                                        null
                                    )
                                        response.results.summary.failed = [];
                                    if (
                                        response?.results?.summary
                                            ?.successful === null
                                    )
                                        response.results.summary.successful =
                                            [];
                                    return response;
                                });

                            return new Response(JSON.stringify(response), {
                                headers: context.response.headers,
                                status: context.response.status,
                                statusText: context.response.statusText,
                            });
                        },
                    },
                ],
            })
        );
    }
}

export const apiClient = new APIClient();
