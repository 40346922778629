import { create } from "zustand";

export interface IAccount {
    accountAlias: string;
    brokerageId: string;
    id: string;
}
interface IGlobalStoreState {
    selectedAccount: IAccount;
    updateSelectedAccount: (selectedAccount: IAccount) => void;
}

export const useGlobalStore = create<IGlobalStoreState>((set) => ({
    selectedAccount: {
        accountAlias: "portfolio",
        id: "portfolio",
        brokerageId: "",
    },
    updateSelectedAccount: (selectedAccount: IAccount) =>
        set({ selectedAccount }),
}));
