const SkeletonTable = () => {
    return (
        <div
            role="status"
            className="w-full p-4 space-y-4 divide-y divide-gray-500 rounded shadow animate-pulse md:p-6"
        >
            {/* Table Header */}
            <div className="flex items-center justify-between space-x-12">
                <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
            </div>

            {/* Table Rows */}
            {Array.from({ length: 5 }).map((_, index) => (
                <div
                    className="flex items-center justify-between pt-4 space-x-12"
                    key={index}
                >
                    <div className="w-1/6 h-2 bg-gray-600 rounded"></div>
                    <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/6 h-2 bg-gray-600 rounded "></div>
                    <div className="w-1/12 h-2 bg-gray-600 rounded "></div>
                </div>
            ))}
        </div>
    );
};

export default SkeletonTable;
