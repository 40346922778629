import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProvider } from "./context/AppContext";
import Dashboard from "./pages/Dashboard";
import Transactions from "./pages/Transactions";
import Holdings from "./pages/Holdings";
import Symbol from "./pages/Symbol";
import Equity from "./pages/Equity";
import Drawdown from "./pages/Drawdown";
import RollingPerformance from "./pages/RollingPerformance";
import RollingRisk from "./pages/RollingRisk";
import Leverage from "./pages/Leverage";
import Drift from "./pages/Drift";
import Returns from "./pages/Returns";
import Allocation from "./pages/Allocation";
import Factor from "./pages/Factor";
import Correlation from "./pages/Correlation";
import Portfolios from "./pages/Portfolios";
import { DialogProvider } from "./providers/DialogContext";
import { MultiSelectProvider } from "./providers/MultiSelectContext";
import { MainWithAuth } from "./layouts/MainWithAuth";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppProvider>
        <DialogProvider>
          <Router>
            <div
              className="bp5-dark"
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                backgroundColor: "#2f343c",
                font: "12px 'Helvetica Neue', helvetica, arial, verdana, sans-serif",
              }}
            >
              <MultiSelectProvider>
                  <Routes>
                    <Route path="/" element={<MainWithAuth />}>
                      <Route index element={<Dashboard />} />
                      <Route path="onboarding" element={<Navigate to="/dashboard" replace />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="/transactions" element={<Transactions />} />
                      <Route path="/holdings" element={<Holdings />} />
                      <Route path="/holdings/:symbolId" element={<Symbol />} />
                      <Route path="/equity" element={<Equity />} />
                      <Route
                        path="/rollingPerformance"
                        element={<RollingPerformance />}
                      />
                      <Route path="/drawdown" element={<Drawdown />} />
                      <Route path="/rollingRisk" element={<RollingRisk />} />
                      <Route path="/leverage" element={<Leverage />} />
                      <Route path="/drift" element={<Drift />} />
                      <Route path="/returns" element={<Returns />} />
                      <Route path="/allocation" element={<Allocation />} />
                      <Route path="/factorAttribution" element={<Factor />} />
                      <Route path="/correlation" element={<Correlation />} />
                      <Route path="/portfolios" element={<Portfolios />} />
                    </Route>
                  </Routes>
              </MultiSelectProvider>
            </div>
          </Router>
        </DialogProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
