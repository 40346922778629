import { Section } from "@blueprintjs/core";
import Volatility from "./Volatility";
import TooltipWithIcon from "../../TooltipWithIcon";

// import DateSelector from "../../DateSelector";

const VolatilityContainer = (props: { id: string }) => {
    return (
        <Section
            title={<TooltipWithIcon title="Portfolio volatility" tip="..." />}
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <Volatility id={props.id} />
        </Section>
    );
};

export default VolatilityContainer;
