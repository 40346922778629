export const currencyFormatter = (value: number, localeOptions?: Intl.NumberFormatOptions): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    ...localeOptions,
  });
  return formatter.format(value);
};

export const percentageFormatter = (value: number, localeOptions?: Intl.NumberFormatOptions): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    ...localeOptions,
  });
  return formatter.format(value);
};

export const scalarFormatter = (value: number, maximumFractionDigits = 4, minimumFractionDigits = 2): string => {
  return `${value.toLocaleString("en-US", {
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  })}`;
};

export const dateFormatter = (date: string | Date, options?: Intl.DateTimeFormatOptions) => {
  const dateToFormat = typeof date === "string" ? new Date(date) : date;

  const finalOptions = { year: "numeric", month: "short", day: "numeric", ...options } as const;
  return dateToFormat.toLocaleDateString(undefined, finalOptions);
};
