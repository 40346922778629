import { create } from "zustand";

interface IDeletePortfolioStoreState {
    portfolioId: string;
    updatePortfolioId: (portfolioId: string) => void;
    resetStore: () => void;
}

export const useDeletePortfolioStore = create<IDeletePortfolioStoreState>(
    (set) => ({
        portfolioId: "",
        updatePortfolioId: (portfolioId: string) => set({ portfolioId }),
        resetStore: () => set({ portfolioId: "" }),
    })
);
