import { GetTransactionsByAccount200Response } from "@tradeblotter/tradeblotter-api";
import { assumeUTC } from "../utils/dates";

const actionFromBuySell = (buySell: string): any => {
    switch (buySell.toUpperCase()) {
        case "BUY":
            return "BUY";
        case "SELL":
            return "SELL";
        case "SELL SHORT":
            return "SELL SHORT";
        default:
            return "UNKNOWN";
    }
};

export default function transformAllTransactionsData(
    response: GetTransactionsByAccount200Response["results"]
) {
    // TODO: This should take start and end dates into account
    const returnData = response.map((result) => ({
        id: result.id,
        tradeDatetime: assumeUTC(result.tradeDatetime),
        symbol: result.symbol,
        brokerageAlias: result.brokerageAlias,
        accountAlias: result.accountAlias,
        accountId: result.accountId,
        quantity: result.quantity,
        price: result.price,
        commission: result.commission,
        action: actionFromBuySell(result.buySell),
        thesis: result.journalEntry,
    }));

    return returnData;
}
