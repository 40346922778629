import { SegmentedControl, Button } from "@blueprintjs/core";

const openDatePicker = () => {};

const options = [
    { label: "1M", value: "1-m" },
    { label: "3M", value: "3-m" },
    { label: "6M", value: "6-m" },
    { label: "9M", value: "9-m" },
    { label: "1Y", value: "1-y" },
    { label: "3Y", value: "3-y" },
    { label: "5Y", value: "5-y" },
    { label: "All", value: "all" },
];
const DateSelector = () => {
    return (
        <div style={{ display: "flex" }}>
            <SegmentedControl options={options} defaultValue="1-y" />
            <Button icon="calendar" onClick={openDatePicker} />
        </div>
    );
};

export default DateSelector;
