import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../api/client";

interface IPortfolioStatsRequest {
    id?: string;
    startDate?: string;
    endDate?: string;
    range?: string;
}

export const usePortfolioStatsAll = (props?: IPortfolioStatsRequest) => {
    const queryKey = ["portfolioStats", "all"];
    props?.id && queryKey.push(...["symbol", props.id]);
    return useQuery({
        queryKey,
        queryFn: () => fetchPortfolioStats({ id: props?.id, range: "all" }),
        staleTime: Infinity,
        refetchOnMount: true,
    });
};

export const usePortfolioStatsYTD = () =>
    useQuery({
        queryKey: ["portfolioStats", "ytd"],
        queryFn: () => fetchPortfolioStats({ range: "ytd" }),
    });

export const usePortfolioStats1d = () =>
    useQuery({
        queryKey: ["portfolioStats", "1d"],
        queryFn: () => fetchPortfolioStats({ range: "1d" }),
    });

const fetchPortfolioStats = async (props: {
    id?: string;
    startDate?: string;
    end_date?: string;
    range?: string;
}) => {
    try {
        const response = await apiClient.api.getPortfolioStats({
            stats: "position_overview_stats,total_portfolio_values,top_holdings,cum_returns,rolling_position_allocation,rolling_annual_volatility,rolling_max_drawdown,rolling_portfolio_value,rolling_sharpe_ratio,returns,rolling_sortino_ratio,alpha,beta,leverage,drawdown_stats,drawdown_periods,aggregate_returns",
        });
        return response;
    } catch (error) {
        console.error(
            "Error in fetchPortfolioStats:",
            (error as Error).message
        );
        throw error;
    }
};
