export const transformSharpeRatioToMonthDataSeries = (
    data: Array<{
        d: string;
        v: string;
    }>
) => {
    // TODO FROM AMANDA:
    // 1. This is missing bpr at the moment, that should be another series
    // 2. This was limited to the last 500 (100) data points, but I set it
    //    to all the data points. Is this a bad idea?
    // const dataPoints = data;
    const v = data.map((item) => {
        const percentage = parseFloat(item.v);
        const value = isNaN(percentage) ? 0 : percentage;
        const date = new Date(item.d).getTime();
        return [date, value];
    });

    return { data: { x: [data[0].d], y: [{ name: "Sharpe ratio", data: v }] } };
};
