function computeBinWidthFreedmanDiaconis(data: Array<{ v: string }>): number {
    const values = data.map((item) => parseFloat(item.v));
    const sortedValues = values.sort((a, b) => a - b);
    const q1 = quantile(sortedValues, 0.25);
    const q3 = quantile(sortedValues, 0.75);
    const iqr = q3 - q1;

    return 2 * iqr * Math.pow(values.length, -1 / 3);
}

function computeBinWidthForFixedBins(
    min: number,
    max: number,
    desiredBins: number
): number {
    return (max - min) / desiredBins;
}

// Helper function to calculate the quantile
function quantile(sortedData: number[], quantile: number): number {
    const pos = (sortedData.length - 1) * quantile;
    const base = Math.floor(pos);
    const rest = pos - base;
    return sortedData[base] + (sortedData[base + 1] - sortedData[base]) * rest;
}

export const binDataForHistogram = (
    data: Array<{ d: string; v: string }>,
    desiredBins = 20,
    useFreedmanDiaconis = false
): { results: { categories: string[]; data: { data: number[] }[] } } => {
    const values = data.map((item) => parseFloat(item.v));

    if (values.length === 0) {
        return { results: { categories: [], data: [{ data: [] }] } };
    }

    const min = Math.min(...values);
    const max = Math.max(...values);

    // Calculate bin width based on the selected method
    const binWidth = useFreedmanDiaconis
        ? computeBinWidthFreedmanDiaconis(data)
        : computeBinWidthForFixedBins(min, max, desiredBins);

    const numBins = Math.ceil((max - min) / binWidth);
    const bins = Array(numBins).fill(0);
    const binLabels = Array(numBins).fill("");

    for (const value of values) {
        let binIndex = Math.floor((value - min) / binWidth);
        if (binIndex >= numBins) binIndex = numBins - 1;
        bins[binIndex]++;
    }

    // Update bin labels to percentages
    binLabels[0] = `< ${(min + binWidth * 100).toFixed(1)}%`;
    for (let i = 1; i < numBins - 1; i++) {
        const lowerBound = (min + i * binWidth) * 100;
        const upperBound = (min + (i + 1) * binWidth) * 100;
        binLabels[i] = `${lowerBound.toFixed(1)}% - ${upperBound.toFixed(1)}%`;
    }
    binLabels[numBins - 1] = `>= ${(max * 100).toFixed(1)}%`;

    return { results: { categories: binLabels, data: [{ data: bins }] } };
};
