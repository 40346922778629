export const OUTSETA_ID = "AppOutseta";
export const OUTSETA_DOMAIN =
    process.env.OUTSETA_DOMAIN || "tradeblotter-dev.outseta.com";
// No matter which site we're on, we want to link to the same knowledge base.
export const OUTSETA_KNOWLEDGE_BASE_URL = `https://docs.tradeblotter.io/support/kb`;

export const OUTSETA_OPTIONS = {
    id: OUTSETA_ID,
    domain: OUTSETA_DOMAIN,
    tokenStorage: "local",
    monitorDom: true,
    translationLang: "en",
    load: "auth,profile,chat,support",
    auth: {
        mode: "embed",
        selector: "#login-embed",
        id: "outseta-auth-script",
        widgetMode: "login|register",
        authenticationCallbackUrl: window.location.href,
    },
};

export const OUTSETA_SCRIPT_SRC = "https://cdn.outseta.com/outseta.min.js";
