import { Section } from "@blueprintjs/core";
// import DateSelector from "../components/DateSelector";
import HistoricAllocation from "../components/widgets/HistoricAllocation/HistoricAllocation";

const Allocation = () => {
    return (
        <Section
            title="Position allocation"
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <HistoricAllocation id="historic-allocation-allocation-page-chart" />
        </Section>
    );
};

export default Allocation;
