import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../api/client";

export const useMyAccounts = () =>
    useQuery({
        queryKey: ["accounts"],
        queryFn: () => fetchMyAccounts(),
    });

const fetchMyAccounts = async () => {
    try {
        const api = apiClient.api;
        const response = await api.getAccounts();
        return response;
    } catch (error) {
        console.error("Error in fetchMyAccounts:", error);
        throw error;
    }
};
