import { AgGridReact } from "ag-grid-react";
import { currencyFormat } from "../../../utils/currencyFormat";
import { percentageFormat } from "../../../utils/percentageFormat";
import { toMax2DigitsFormat } from "../../../utils/toMax2DigitsFormat";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import SkeletonTable from "../../Skeletons/SkeletonTable";
import { useGlobalStore } from "../../../store/global";
import { usePositionTableStats } from "../../../hooks/usePositionTableStats";

// interface IRowData {
//     quantity: string;
//     avgCostPerShare: string;
//     lastPrice: string;
// }

// const pagination = true,
//     paginationPageSize = 10,
//     paginationPageSizeSelector = [10, 20, 50, 100];

const typeSize = "fitCellContents" as const;
const autoSizeStrategy = {
    type: typeSize,
};

export const PositionStats = () => {
    const { data, isLoading } = usePositionTableStats();
    const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
    const positionStatsData = data?.results.data;
    const transformedData = transformPositionStatsDataResponse(
        positionStatsData || [],
        selectedPortfolio.id
    );

    if (isLoading) {
        return <SkeletonTable />;
    }
    return (
        <AgGridReact
            // pagination={pagination}
            // paginationPageSize={paginationPageSize}
            // paginationPageSizeSelector={paginationPageSizeSelector}
            rowData={transformedData || []}
            // @ts-expect-error
            columnDefs={colDefs(selectedPortfolio.id)}
            autoSizeStrategy={autoSizeStrategy}
        />
    );
};

const cellRenderer = (params: { value: string }) => {
    return (
        <a href={`/symbol/${params.value}`} target="_blank" rel="noreferrer">
            {params.value}
        </a>
    );
};

const cellClassRules = {
    "rag-green": (params: { value: number }) => params.value > 0,
    "rag-red": (params: { value: number }) => params.value < 0,
};

const colDefs = (selectedPortfolioId: string) => [
    {
        field: "symbol",
        headerName: "Ticker",
        cellRenderer: (params: { value: string }) =>
            cellRenderer({ value: params.value }),
    },
    {
        field: "quantity",
        headerName: "Quantity",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            Number(p.value[selectedPortfolioId]).toFixed(0),
    },
    {
        field: "avgCostPerShare",
        headerName: "Average Cost Per Share",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            currencyFormat(Number(p.value[selectedPortfolioId])),
    },
    {
        field: "costBasis",
        headerName: "Cost Basis",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            currencyFormat(p.value[selectedPortfolioId]),
    },
    {
        field: "lastPrice",
        headerName: "Last Price",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            currencyFormat(p.value[selectedPortfolioId]),
    },
    {
        field: "value",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            currencyFormat(p.value[selectedPortfolioId]),
    },
    // @NOTE: not defined in API
    {
        field: "gain",
        headerName: "Gain",
        valueGetter: (params: { data: { [key: string]: any } }) =>
            (parseFloat(params.data.lastPrice[selectedPortfolioId]) -
                parseFloat(params.data.avgCostPerShare[selectedPortfolioId])) *
            parseFloat(params.data.quantity[selectedPortfolioId]),
        cellClassRules: cellClassRules,
        valueFormatter: (p: { value: number }) => currencyFormat(p.value),
    },
    // @NOTE: not defined in API
    {
        field: "gainPercentage",
        headerName: "Gain Percentage",
        valueGetter: (params: { data: { [key: string]: any } }) =>
            (parseFloat(params.data.lastPrice[selectedPortfolioId]) -
                parseFloat(params.data.avgCostPerShare[selectedPortfolioId])) /
            parseFloat(params.data.lastPrice[selectedPortfolioId]),
        cellClassRules: cellClassRules,
        valueFormatter: (p: { value: number }) => percentageFormat(p.value),
    },
    {
        field: "weight",
        headerName: "Portfolio Percentage",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            percentageFormat(p.value[selectedPortfolioId]),
    },
    {
        field: "sharpeRatio",
        headerName: "Sharpe Ratio",
        cellClassRules: cellClassRules,
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            toMax2DigitsFormat(p.value[selectedPortfolioId]),
    },
    {
        field: "valueAtRisk",
        headerName: "Value at Risk",
        valueFormatter: (p: { value: { [key: string]: number } }) =>
            currencyFormat(p.value[selectedPortfolioId]),
    },
];

// @TODO: fix type, from API it's object but should be PositionStatsDict
const transformPositionStatsDataResponse = (
    data: object | never[],
    selectedPortfolioId: string
) => {
    const keys = Object.keys(data);
    // @ts-expect-error
    if (keys.length < 1 || data?.length === 0) return [];
    const result = keys?.map((item) => {
        // If the selected portfolio is not portfolio, return all data
        if (selectedPortfolioId === "portfolio") {
            return {
                // @ts-expect-error
                ...data[item],
                symbol: item,
                portfolio: selectedPortfolioId,
            };
        } else {
            const isSelectedPortfolioInData =
                // @ts-expect-error
                data[item].accountId["portfolio"].includes(selectedPortfolioId);
            if (!isSelectedPortfolioInData) return null;

            return {
                // @ts-expect-error
                ...data[item],
                symbol: item,
                portfolio: selectedPortfolioId,
            };
        }
    });

    return result.filter((item) => item !== null);
};

export default PositionStats;
