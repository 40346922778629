import { useIsInView } from "../../../hooks/useIsInView";
import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import LineChart from "../../Charts/LineChart";
import SkeletonVerticalChart from "../../Charts/placeholders/SkeletonVerticalChart";
import { useTransformVolatilityToMonthData } from "./hooks/useTransformVolatilityToMonthData";

const Volatility = (props: { id: string }) => {
    const { isInView, ref } = useIsInView();
    const { isLoading: isLoadingPortfolioStats } = usePortfolioStatsAll();
    const { data: transformedData, isLoading } =
        useTransformVolatilityToMonthData(isInView);

    return (
        <div ref={ref}>
            {isLoadingPortfolioStats || isLoading ? (
                <SkeletonVerticalChart />
            ) : (
                <LineChart
                    categories={transformedData?.data.x || []}
                    data={transformedData?.data.y || []}
                    options={{
                        chart: {
                            id: props.id,
                            animations: {
                                enabled: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                        },
                        xaxis: {
                            type: "datetime",
                        },
                        yaxis: {
                            labels: {
                                formatter: (value) =>
                                    `${(value * 100).toFixed(0)}%`,
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default Volatility;
