const SkeletonHorizontalChart = () => {
    return (
        <div
            role="status"
            className="w-full h-[415px] p-4 rounded shadow animate-pulse dark:border-gray-700"
        >
            <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-32 mb-4"></div>
            <div className="w-48 h-2 mb-10 bg-gray-600 rounded-full dark:bg-gray-700"></div>
            <div className="flex flex-col space-y-4">
                <div className="w-[60%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
                <div className="w-[40%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
                <div className="w-[80%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
                <div className="w-[60%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
                <div className="w-[40%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
                <div className="w-[80%] h-8 bg-gray-600 rounded-lg dark:bg-gray-700"></div>
            </div>
        </div>
    );
};

export default SkeletonHorizontalChart;
