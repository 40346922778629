import React from 'react';
import { Section } from "@blueprintjs/core";
import DateSelector from '../components/DateSelector';
import HeatmapChart from '../components/Charts/HeatmapChart';


const CorrelationHeatmap = () => {
    var data = [
        {
            "name": "MTUM",
            "data": [
                { "x": "MTUM", "y": 1 },
                { "x": "QUAL", "y": 0.8224206463 },
                { "x": "SIZE", "y": 0.7993496118 },
                { "x": "USMV", "y": 0.7514857458 },
                { "x": "VLUE", "y": 0.7474684179 }
            ]
        },
        {
            "name": "QUAL",
            "data": [
                { "x": "MTUM", "y": 0.8224206463 },
                { "x": "QUAL", "y": 1 },
                { "x": "SIZE", "y": 0.9403573235 },
                { "x": "USMV", "y": 0.8985214774 },
                { "x": "VLUE", "y": 0.8634611855 }
            ]
        },
        {
            "name": "SIZE",
            "data": [
                { "x": "MTUM", "y": 0.7993496118 },
                { "x": "QUAL", "y": 0.9403573235 },
                { "x": "SIZE", "y": 1 },
                { "x": "USMV", "y": 0.8739531912 },
                { "x": "VLUE", "y": 0.9278282958 }
            ]
        },
        {
            "name": "USMV",
            "data": [
                { "x": "MTUM", "y": 0.7514857458 },
                { "x": "QUAL", "y": 0.8985214774 },
                { "x": "SIZE", "y": 0.8739531912 },
                { "x": "USMV", "y": 1 },
                { "x": "VLUE", "y": 0.8075527631 }
            ]
        },
        {
            "name": "VLUE",
            "data": [
                { "x": "MTUM", "y": 0.7474684179 },
                { "x": "QUAL", "y": 0.8634611855 },
                { "x": "SIZE", "y": 0.9278282958 },
                { "x": "USMV", "y": 0.8075527631 },
                { "x": "VLUE", "y": 1 }
            ]
        }
    ];

    return (
        <Section
            title="Correlation analysis"
            rightElement={<DateSelector />}
            style={{ padding: '2px', marginBottom: '20px' }}
        >
            <HeatmapChart data={data} />
        </Section >
    );

};


const Correlation = () => {
    return (
        <div>
            <CorrelationHeatmap />
        </div>
    );
};

export default Correlation;