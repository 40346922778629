import { Section } from "@blueprintjs/core";
// import DateSelector from "../components/DateSelector";
import LineChart from "../components/Charts/LineChart";
import BarChart from "../components/Charts/BarChart";
import ChartContainer from "../components/containers/ChartContainer";
import PositionStatsContainer from "../components/widgets/PositionsStats/PositionStatsContainer";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-grid.css";

const HoldingsTable = () => {
    return <PositionStatsContainer />;
};

const TotalHoldings = () => {
    // const data = [
    //     {
    //         name: "Total holdings",
    //         data: [10, 11, 14, 9, 16, 14, 11, 12, 11],
    //     },
    // ];

    // const categories = [
    //     "2024-01-01",
    //     "2024-02-01",
    //     "2024-03-01",
    //     "2024-04-01",
    //     "2024-05-01",
    //     "2024-06-01",
    //     "2024-07-01",
    //     "2024-08-01",
    //     "2024-09-01",
    // ];

    return (
        <Section
            title="Average holdings"
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <BarChart
                data={[]}
                categories={[]}
                options={{
                    xaxis: {
                        labels: {
                            style: {
                                colors: ["#ffffff"],
                            },
                        },
                    },
                    noData: {
                        text: "Coming soon...",
                    },
                }}
            />
        </Section>
    );
};

const LongShortHoldingsCount = () => {
    // const data = [
    //     {
    //         name: "Long holdings",
    //         data: [10, 11, 14, 9, 16, 14, 11, 12, 11],
    //     },
    //     {
    //         name: "Short holdings",
    //         data: [2, 3, 1, 2, 6, 9, 12, 15, 6],
    //     },
    // ];

    // const categories = [
    //     "2024-01-01",
    //     "2024-02-01",
    //     "2024-03-01",
    //     "2024-04-01",
    //     "2024-05-01",
    //     "2024-06-01",
    //     "2024-07-01",
    //     "2024-08-01",
    // ];

    return (
        <Section
            title="Long and short holdings"
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <BarChart
                data={[]}
                categories={[]}
                options={{
                    noData: {
                        text: "Coming soon...",
                    },
                }}
            />
        </Section>
    );
};

const DailyTurnover = () => {
    // const data = [
    //     {
    //         name: "Average daily turnover",
    //         data: [0.1, 0.11, 0.14, 0.09, 0.16, 0.14, 0.11, 0.12, 0.11],
    //     },
    // ];

    // const categories = [
    //     "2024-01-01",
    //     "2024-02-01",
    //     "2024-03-01",
    //     "2024-04-01",
    //     "2024-05-01",
    //     "2024-06-01",
    //     "2024-07-01",
    //     "2024-08-01",
    // ];

    return (
        <Section
            title="Average daily turnover"
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <LineChart
                data={[]}
                categories={[]}
                options={{
                    noData: {
                        text: "Coming soon...",
                    },
                }}
            />
        </Section>
    );
};

const Holdings = () => {
    return (
        <div>
            <HoldingsTable />
            <TotalHoldings />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px", // This adds space between the cards
                    flexWrap: "wrap", // This allows cards to wrap to next line on smaller screens
                }}
            >
                <ChartContainer>
                    <LongShortHoldingsCount />
                </ChartContainer>
                <ChartContainer>
                    <DailyTurnover />
                </ChartContainer>
            </div>
        </div>
    );
};

export default Holdings;
