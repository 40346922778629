import { useIsInView } from "../../../hooks/useIsInView";
import { usePortfolioStatsAll } from "../../../hooks/usePortfolioStats";
import { percentageFormatter } from "../../../utils/formatters";
import LineChart from "../../Charts/LineChart";
import SkeletonVerticalChart from "../../Charts/placeholders/SkeletonVerticalChart";
import { useTransformPortfolioToMonthData } from "./hooks/useTransformPortfolioToMonthData";

const PortfolioPerformance = (props: { id: string }) => {
    const { isInView, ref } = useIsInView();
    const { isLoading: isLoadingPortfolioStats } = usePortfolioStatsAll();
    const { data: transformedData, isLoading } =
        useTransformPortfolioToMonthData(isInView);

    return (
        <div ref={ref}>
            {isLoadingPortfolioStats || isLoading ? (
                <SkeletonVerticalChart />
            ) : (
                <LineChart
                    categories={transformedData?.data.x || []}
                    data={transformedData?.data.y || []}
                    options={{
                        chart: {
                            id: props.id,
                            animations: {
                                enabled: false,
                            },
                            zoom: {
                                enabled: false,
                            },
                        },
                        xaxis: {
                            type: "datetime",
                        },
                        yaxis: {
                            labels: {
                                formatter: (value) =>
                                    percentageFormatter(Number(value) / 10, {
                                        maximumFractionDigits: 0,
                                    }),
                            },
                        },
                    }}
                />
            )}
        </div>
    );
};

export default PortfolioPerformance;
