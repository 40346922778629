import * as React from "react";
import { useSearchParams } from "react-router-dom";

import type { TAuthState, TAuthStatus } from "./AuthProvider.types";
import type {
    TOutseta,
    TOutsetaAuthOptions,
    TOutsetaUser,
} from "./providers/outseta";
import loadAuthProvider from "./providers";

const initialState = {
    user: null,
    isLoading: false,
    logout: () => {},
    openLogin: async () => {},
    openSignup: async () => {},
    openProfile: async () => {},
    openPlan: async () => {},
};

export const AuthContext = React.createContext<TAuthState>(initialState);

type TProps = {
    children: React.ReactNode;
};

export const AuthProvider: React.FC<TProps> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = React.useState<TAuthStatus>("init");
    const [user, setUser] = React.useState<TOutsetaUser | null>(null);
    const outsetaRef = React.useRef<TOutseta>();

    const updateUser = React.useCallback(async () => {
        const outsetaUser = await outsetaRef.current?.getUser();

        if (outsetaUser) {
            setUser(outsetaUser);
            setStatus("ready");
        }
    }, []);

    const handleOutsetaUserEvents = React.useCallback((onEvent: () => void) => {
        outsetaRef.current?.on("subscription.update", onEvent);
        outsetaRef.current?.on("profile.update", onEvent);
        outsetaRef.current?.on("account.update", onEvent);
    }, []);

    const logout = React.useCallback(() => {
        outsetaRef.current?.setAccessToken("");
        setUser(null);
    }, []);

    const openLogin = React.useCallback(
        async (options: TOutsetaAuthOptions = {}) => {
            outsetaRef.current?.auth.open({
                widgetMode: "login|register",
                authenticationCallbackUrl: window.location.href,
                ...options,
            });
        },
        []
    );

    const openSignup = React.useCallback(
        async (options: TOutsetaAuthOptions = {}) => {
            outsetaRef.current?.auth.open({
                widgetMode: "register",
                authenticationCallbackUrl: window.location.href,
                ...options,
            });
        },
        []
    );

    const openProfile = React.useCallback(
        async (options: TOutsetaAuthOptions = {}) => {
            outsetaRef.current?.profile.open({ tab: "profile", ...options });
        },
        []
    );

    const openPlan = React.useCallback(
        async (options: TOutsetaAuthOptions = {}) => {
            outsetaRef.current?.profile.open({ tab: "plan", ...options });
        },
        []
    );

    React.useEffect(() => {
        const init = async () => {
            outsetaRef.current = await loadAuthProvider("outseta");

            if (!outsetaRef.current) {
                throw new Error("Outseta provider not found");
            }
            handleOutsetaUserEvents(updateUser);

            const accessToken = searchParams.get("access_token");

            if (accessToken) {
                outsetaRef.current.setAccessToken(accessToken);
                setSearchParams({});
            }

            if (outsetaRef.current.getAccessToken()) {
                updateUser();
            } else {
                setStatus("ready");
            }
        };

        init();

        return () => {
            handleOutsetaUserEvents(() => {});
        };
    }, [handleOutsetaUserEvents, searchParams, setSearchParams, updateUser]);

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoading: status !== "ready",
                logout,
                openLogin,
                openSignup,
                openProfile,
                openPlan,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
