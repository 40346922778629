import { OUTSETA_OPTIONS, OUTSETA_SCRIPT_SRC } from "./outseta.constants";
import type { TOutseta, TOutsetaOptions } from "./outseta.types";

declare global {
  interface Window {
    AppOutseta: TOutseta;
    AppOutsetaOptions: TOutsetaOptions;
  }
}

let outsetaPromise: Promise<TOutseta> | null = null;

const createScript = () => {
  const optionsKey = "AppOutsetaOptions";

  window[optionsKey] = OUTSETA_OPTIONS;

  const script = document.createElement("script");

  script.src = OUTSETA_SCRIPT_SRC;
  script.dataset.options = "AppOutsetaOptions";

  return script;
};

export const loadOutseta = async () => {
  if (outsetaPromise) {
    return outsetaPromise;
  }

  outsetaPromise = new Promise((resolve, reject) => {
    if (window.AppOutseta) {
      resolve(window.AppOutseta);
      return;
    }

    const script = createScript();

    script.onload = () => {
      if (window.AppOutseta) {
        resolve(window.AppOutseta);
        return;
      }

      reject(new Error("Outseta.js not available"));
    };

    script.onerror = () => {
      reject(new Error("Failed to load Outseta.js"));
    };

    document.head.appendChild(script);
  });

  return outsetaPromise;
};
