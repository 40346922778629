import { Card } from "@blueprintjs/core";

const CardContainer = (props: {
    children: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    const { children, style } = props;
    const baseStyle = {
        flex: "1 1 300px",
        minWidth: "250px",
        marginBottom: "20px",
    };
    return <Card style={style ? style : baseStyle}>{children}</Card>;
};

export default CardContainer;
