import { enabledChartAnimation } from "../../config";
import { usePortfolioStatsAll } from "../../hooks/usePortfolioStats";
import SparkBarChart from "../Charts/SparkBarChart";
import { useTransformValueAtRisk } from "./hooks/useTransformValueAtRisk";
import { SkeletonVerticalChartForCard } from "../Skeletons/SkeletonCard";

const ValueAtRiskChart = () => {
    const { isLoading } = usePortfolioStatsAll();
    const {
        transformedValueAtRiskData: { data, isError },
        binWithVarValue,
    } = useTransformValueAtRisk();

    if (isLoading) {
        return <SkeletonVerticalChartForCard />;
    }

    if (isError) {
        return <div>Error</div>;
    }

    return (
        <SparkBarChart
            data={data?.results?.data || []}
            options={{
                chart: {
                    id: "value-at-risk-chart-dashboard",
                    animations: {
                        enabled: enabledChartAnimation,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                annotations: {
                    xaxis: [
                        {
                            x: binWithVarValue, // x-axis value where the line should be drawn
                            borderColor: "#775DD0", // color of the line
                        },
                    ],
                },
                noData: {
                    text: isLoading ? "Loading..." : "No data available",
                },
            }}
        />
    );
};

export default ValueAtRiskChart;
