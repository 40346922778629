import {
    CreateAccount200Response,
    GetAccounts200Response,
} from "@tradeblotter/tradeblotter-api";
import { apiClient } from "../client";
import { config } from "../../config";
import { getOutsetaToken } from "../../utils";

const baseUrl = config.API_BASE_URL;

interface IAccount {
    accountId: string;
    brokerageId?: string;
    capitalBase?: number;
    description?: string;
    benchmarkSymbol?: string;
    accountAlias?: string;
}

// POST /accounts/{id}
export const updateAccountOrThrow = async ({
    accountId,
    brokerageId,
    capitalBase,
    benchmarkSymbol,
    description,
    accountAlias,
}: IAccount): Promise<CreateAccount200Response> => {
    try {
        const response = await apiClient.api.updateAccount({
            accountId,
            brokerageId,
            capitalBase: `${capitalBase}`,
            benchmarkSymbol,
            description,
            accountAlias,
        });
        return response;
    } catch (error) {
        console.error(
            `Error updating starting cash balance for account ${accountId}:`,
            {
                brokerageId,
                capitalBase,
                message: (error as Error).message,
            }
        );
        throw error;
    }
};

export const createAccount = async ({
    accountAlias,
    benchmarkSymbol,
    description,
}: {
    accountAlias: string;
    benchmarkSymbol?: string;
    description?: string;
    capitalBase?: number;
}): Promise<CreateAccount200Response> => {
    try {
        const api = apiClient.api;

        const response = await api.createAccount({
            accountAlias: accountAlias,
            brokerageId: "",
            benchmarkSymbol: "SPY",
            description,
            capitalBase: "0",
        });
        return response;
    } catch (error) {
        console.error(
            `Error creating account for account alias ${accountAlias}:`,
            {
                benchmarkSymbol,
                description,
                message: (error as Error).message,
            }
        );
        throw error;
    }
};

export const provisionalCreateAccount = async ({
    accountAlias,
}: {
    accountAlias: string;
}): Promise<CreateAccount200Response> => {
    try {
        const accountUrl = new URL(`${baseUrl}/accounts`);
        accountUrl.searchParams.append("account_alias", accountAlias);
        accountUrl.searchParams.append("brokerage_id", "");
        accountUrl.searchParams.append("cash_balance", "0");
        accountUrl.searchParams.append("benchmark_symbol", "SPY");
        accountUrl.searchParams.append("description", "");

        const result = await fetch(accountUrl.toString(), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getOutsetaToken()}`,
                "Content-Type": "application/json",
            },
        });

        if (!result.ok) {
            const errorResponse = await result.json();
            throw new Error(
                `Failed to create account: ${
                    errorResponse.message || result.statusText
                }`
            );
        }
        const response = await result.json();
        return response;
    } catch (error) {
        console.error(
            "Error creating account for account alias",
            (error as Error).message
        );
        throw error;
    }
};

export const deleteAccount = async ({
    accountId,
}: {
    accountId: string;
}): Promise<void> => {
    try {
        const api = apiClient.api;
        const response = await api.deleteAccount({
            accountId,
        });
        return response;
    } catch (error) {
        console.error(`Error deleting account for account id ${accountId}:`, {
            message: (error as Error).message,
        });
        throw error;
    }
};

export const updateAccountCapitalBaseOrThrow = async ({
    accountId,
    capitalBase,
}: {
    accountId: string;
    capitalBase: number;
}): Promise<void> => {
    try {
        const accountUrl = new URL(`${baseUrl}/accounts/${accountId}`);
        accountUrl.searchParams.append("capital_base", capitalBase.toString());

        const response = await fetch(accountUrl.toString(), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${getOutsetaToken()}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(
                `Failed to update account: ${
                    errorResponse.message || response.statusText
                }`
            );
        }
    } catch (error) {
        console.error(`Error updating capital base for account ${accountId}:`, {
            capitalBase,
            message: (error as Error).message,
        });
        throw error;
    }
};

export const getAccounts = async (): Promise<GetAccounts200Response> => {
    try {
        const response = await apiClient.api.getAccounts();
        return response;
    } catch (error) {
        console.error("Error in getAccounts:", (error as Error).message);
        throw error;
    }
};
