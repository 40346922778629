import { useGlobalStore } from "../store/global";
import { Section } from "@blueprintjs/core";
// import DateSelector from "../components/DateSelector";
import LineChart from "../components/Charts/LineChart";
import { usePortfolioStatsAll } from "../hooks/usePortfolioStats";
import { transformPortfolioToMonthDataSeries } from "../data-transformers/portfolio-to-month-series-data";
import SkeletonVerticalChart from "../components/Charts/placeholders/SkeletonVerticalChart";
import { percentageFormatter } from "../utils/formatters";

const Equity = () => {
    const { data, isLoading, isError } = usePortfolioStatsAll();
    const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);
    const selectedAccount = selectedPortfolio || "portfolio";

    if (isError) {
        return <div>Error</div>;
    }

    const { data: transformedData } = transformPortfolioToMonthDataSeries(
        data?.results?.cumReturns?.data[selectedAccount.id] || []
    );

    return (
        <Section
            title={"Portfolio equity"}
            subtitle="A benchmark is a standard or measure that can be used to analyze the allocation, risk, and return of a given portfolio. Individual funds and investment portfolios will generally have established benchmarks for standard analysis. A variety of benchmarks can also be used to understand how a portfolio is performing against various market segments."
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            {isLoading ? (
                <SkeletonVerticalChart />
            ) : (
                <LineChart
                    categories={transformedData.x}
                    data={transformedData.y || []}
                    options={{
                        yaxis: {
                            labels: {
                                formatter: (value) =>
                                    percentageFormatter(Number(value) / 10, {
                                        maximumFractionDigits: 0,
                                    }),
                            },
                        },
                        xaxis: {
                            type: "datetime",
                        },
                    }}
                />
            )}
        </Section>
    );
};

export default Equity;
