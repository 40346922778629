import React from "react";
import { Menu, MenuItem, MenuDivider, IconName } from "@blueprintjs/core";
import { useLocation, useNavigate } from "react-router-dom";

interface SidebarMenuItemProps {
    icon?: IconName;
    text: string;
    href: string;
    children?: React.ReactNode;
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
    icon,
    text,
    href,
    children,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <MenuItem
            icon={icon}
            text={text}
            // href={href}
            onClick={() => navigate(href)}
            active={location.pathname === href}
        >
            {children}
        </MenuItem>
    );
};

const Sidebar = () => {
    // const location = useLocation();
    // const currentPath = location.pathname;
    return (
        <div
            style={{
                width: "200px",
                backgroundColor: "#f5f8fa",
                borderRight: "1px solid #585d63",
                display: "flex",
                flexDirection: "column",
                height: "100%", // This ensures the sidebar takes full height
            }}
        >
            <Menu
                style={{
                    flexGrow: 1, // This allows the menu to grow and fill the sidebar
                    overflowY: "auto", // This enables scrolling if content overflows
                    overflowX: "hidden", // Prevents horizontal scrolling
                }}
            >
                <MenuDivider title="Overview" />
                <SidebarMenuItem
                    icon="dashboard"
                    text="Dashboard"
                    href="/dashboard"
                />
                <SidebarMenuItem
                    icon="folder-open"
                    text="Portfolios"
                    href="/portfolios"
                />
                {/* <SidebarMenuItem
                    icon="notifications"
                    text="Alerts"
                    href="/notifications"
                /> */}
                <MenuDivider title="Performance" />
                <SidebarMenuItem
                    icon="chart"
                    text="Equity curve"
                    href="/equity"
                />
                <SidebarMenuItem
                    icon="vertical-bar-chart-asc"
                    text="Rolling performance"
                    href="/rollingPerformance"
                />
                <SidebarMenuItem
                    icon="function"
                    text="Return statistics"
                    href="/returns"
                />
                <SidebarMenuItem
                    icon="percentage"
                    text="Trading statistics"
                    href="#"
                >
                    <h2 style={{ padding: "10px", cursor: "pointer" }}>
                        Coming soon...
                    </h2>
                </SidebarMenuItem>
                <MenuDivider title="Portfolio" />
                <SidebarMenuItem
                    icon="pie-chart"
                    text="Allocation"
                    href="/allocation"
                />
                <SidebarMenuItem
                    icon="bullseye"
                    text="Holdings"
                    href="/holdings"
                />
                <SidebarMenuItem
                    icon="exchange"
                    text="Transactions"
                    href="/transactions"
                />
                <SidebarMenuItem
                    icon="layers"
                    text="Leverage"
                    href="/leverage"
                />
                <SidebarMenuItem
                    icon="ship"
                    text="Drift analysis"
                    href="#"
                    // href="/drift"
                >
                    <h2 style={{ padding: "10px", cursor: "pointer" }}>
                        Coming soon...
                    </h2>
                </SidebarMenuItem>
                <SidebarMenuItem
                    icon="info-sign"
                    text="Information analysis"
                    // href="/information"
                    href="#"
                >
                    <h2 style={{ padding: "10px", cursor: "pointer" }}>
                        Coming soon...
                    </h2>
                </SidebarMenuItem>
                <MenuDivider title="Risk" />
                <SidebarMenuItem
                    icon="vertical-bar-chart-desc"
                    text="Drawdown"
                    href="/drawdown"
                />
                <SidebarMenuItem
                    icon="timeline-area-chart"
                    text="Rolling risk"
                    href="/rollingRisk"
                />
                <SidebarMenuItem
                    icon="inner-join"
                    text="Factor attribution"
                    // href="/factorAttribution"
                    href="#"
                >
                    <h2 style={{ padding: "10px", cursor: "pointer" }}>
                        Coming soon...
                    </h2>
                </SidebarMenuItem>
                <SidebarMenuItem
                    icon="heat-grid"
                    text="Correlation analysis"
                    // href="/correlation"
                    href="#"
                >
                    <h2 style={{ padding: "10px", cursor: "pointer" }}>
                        Coming soon...
                    </h2>
                </SidebarMenuItem>
            </Menu>
        </div>
    );
};

export default Sidebar;
