import { loadOutseta } from "./outseta";

enum Providers {
    outseta = "outseta",
}

type ProvidersKey = keyof typeof Providers;

const providers: Record<ProvidersKey, () => Promise<any>> = {
    outseta: loadOutseta,
};

const loadedProviders: Record<ProvidersKey, Promise<any> | null> = {
    outseta: null,
};

const loadAuthProvider = async (providerName: ProvidersKey) => {
    if (!loadedProviders[providerName]) {
        loadedProviders[providerName] = providers[providerName]();
    }

    try {
        const provider = await loadedProviders[providerName];
        return provider;
    } catch (error) {
        loadedProviders[providerName] = null; // Reset in case of failure
        throw error;
    }
};

export default loadAuthProvider;
