import { useEffect } from "react";
import { useMultiSelect } from "../providers/MultiSelectContext";
import { useParams } from "react-router-dom";

const Symbol = () => {
    const { symbolId } = useParams();

    const { disableMultiSelect } = useMultiSelect();
    useEffect(() => {
        disableMultiSelect();
    }, [disableMultiSelect]);
    return (
        <div>
            <h1>Symbol {symbolId}</h1>
        </div>
    );
};

export default Symbol;
