import { Section } from "@blueprintjs/core";
import PositionStats from "./PositionStats";
import TooltipWithIcon from "../../TooltipWithIcon";
// import DateSelector from "../../DateSelector";

const PositionStatsContainer = () => {
    return (
        <Section
            title={
                <TooltipWithIcon
                    title={"Position Stats"}
                    tip="Here you can see the stats of your positions."
                />
            }
            // rightElement={<DateSelector />}
            style={{ padding: "2px", marginBottom: "20px" }}
        >
            <div
                className="ag-theme-balham-auto-dark" // applying the Data Grid theme
                style={{ height: 375 }} // the Data Grid will fill the size of the parent container
            >
                <PositionStats />
            </div>
        </Section>
    );
};

export default PositionStatsContainer;
