import { Cell } from "@blueprintjs/table";
import { Navbar, Button, Alignment } from "@blueprintjs/core";
import { AgGridReact } from "ag-grid-react";

import SkeletonTable from "../../components/Skeletons/SkeletonTable";
import DateSelector from "../../components/DateSelector";
import { useDialog } from "../../providers/DialogContext";
import { useTransformTransactions } from "./transformation-hooks/useTransformTransactions";
import { useGlobalStore } from "../../store/global";
import { dateFormatter } from "../../utils/formatters";
import { currencyFormat } from "../../utils/currencyFormat";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const Transactions = () => {
    const { openDialog } = useDialog();
    const handleMenuItemClick = (option: string) => {
        openDialog(option);
    };

    const selectedPortfolio = useGlobalStore((state) => state.selectedAccount);

    const pagination = true,
        paginationPageSize = 50,
        paginationPageSizeSelector = [50, 100];

    const { data: rowData, isLoading } = useTransformTransactions({
        id: selectedPortfolio.id,
        brokerageId: selectedPortfolio.brokerageId,
    });

    return (
        <div className="panel transactions-panel">
            {isLoading ? (
                <SkeletonTable />
            ) : (
                <>
                    <Navbar>
                        <Navbar.Group align={Alignment.LEFT}>
                            <Button
                                className="bp5-minimal"
                                icon="add"
                                text="Add transaction"
                                onClick={() =>
                                    handleMenuItemClick(
                                        "create-transaction-dialog"
                                    )
                                }
                            />
                            <Button
                                className="bp5-minimal"
                                icon="add"
                                text="Adjust cash"
                                onClick={() =>
                                    handleMenuItemClick("adjust-cash-dialog")
                                }
                            />
                        </Navbar.Group>

                        <Navbar.Group align={Alignment.RIGHT}>
                            <DateSelector />
                        </Navbar.Group>
                    </Navbar>
                    <div
                        className="ag-theme-balham-auto-dark" // applying the Data Grid theme
                        style={{ height: "600px" }} // the Data Grid will fill the size of the parent container
                    >
                        <AgGridReact
                            pagination={pagination}
                            paginationPageSize={paginationPageSize}
                            paginationPageSizeSelector={
                                paginationPageSizeSelector
                            }
                            rowData={rowData}
                            // @ts-expect-error
                            columnDefs={colDefs}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const colDefs = [
    {
        field: "tradeDatetime",
        headerName: "Trade Datetime",
        cellRenderer: (params: { value: string }) => {
            return <>{dateFormatter(params.value)}</>;
        },
        sort: "desc",
    },
    {
        field: "symbol",
        headerName: "Symbol",
        cellRenderer: (params: { value: string }) => {
            return <>{params.value}</>;
        },
    },
    // {
    //     field: "brokerageAlias",
    //     headerName: "Brokerage",
    //     cellRenderer: (params: { value: string }) => {
    //         return <>{params.value}</>;
    //     },
    // },
    {
        field: "accountAlias",
        headerName: "Account",
        cellRenderer: (params: { value: string }) => {
            return <>{params.value}</>;
        },
    },
    {
        field: "quantity",
        headerName: "Quantity",
    },
    {
        field: "price",
        headerName: "Price",
        cellRenderer: (params: { value: string }) => {
            return <>{currencyFormat(Number(params.value))}</>;
        },
    },
    {
        field: "commission",
        headerName: "Commission",
        cellRenderer: (params: { value: string }) => {
            return <>{currencyFormat(Number(params.value))}</>;
        },
    },
    {
        field: "action",
        headerName: "Action",
        cellRenderer: (params: { value: string }) => {
            return <>{params.value}</>;
        },
    },
    {
        field: "thesis",
        headerName: "Thesis",
        flex: 1,
        cellRenderer: (params: { value: string }) => {
            return <>{params.value}</>;
        },
    },
];

export default Transactions;
