import { Card } from "../../../@/components/ui/card";

const SkeletonCard = () => {
    return (
        <Card>
            <div className="p-6 shadow animate-pulse h-[260px]">
                <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-600 rounded-full dark:bg-gray-700 mb-4"></div>

                <div className="h-2 bg-gray-600 rounded-full dark:bg-gray-700"></div>
                {/* <div className="flex items-center mt-4">
                    <div>
                        <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-32 mb-4"></div>
                        <div className="w-48 h-2 bg-gray-600 rounded-full dark:bg-gray-700"></div>
                    </div>
                </div> */}
                <SkeletonVerticalChartForCard />
            </div>
        </Card>
    );
};

export const SkeletonVerticalChartForCard = () => {
    return (
        <div className="flex items-baseline mt-24">
            <div className="w-full bg-gray-600 rounded-t-lg h-14 dark:bg-gray-700"></div>
            <div className="w-full h-24 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full bg-gray-600 rounded-t-lg h-14 ms-3 dark:bg-gray-700"></div>
            <div className="w-full h-24 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-14 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-24 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-14 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-24 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-14 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
            <div className="w-full h-24 ms-3 bg-gray-600 rounded-t-lg dark:bg-gray-700"></div>
        </div>
    );
};

export default SkeletonCard;
