const CardsContainer = (props: { children: React.ReactNode }) => {
    const { children } = props;
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px", // This adds space between the cards
                flexWrap: "wrap", // This allows cards to wrap to next line on smaller screens
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "20px",
            }}
        >
            {children}
        </div>
    );
};

export default CardsContainer;
