import { Section } from "@blueprintjs/core";
import TooltipWithIcon from "../../TooltipWithIcon";

import Allocation from "./Allocation";
// import DateSelector from "../../DateSelector";

const AllocationContainer = (props: { id: string }) => {
    return (
        <Section
            title={
                <TooltipWithIcon
                    title="Position weights"
                    tip="Here you can see the allocation of your positions."
                />
            }
            // rightElement={<DateSelector />}
            style={{ padding: "2px" }}
        >
            <Allocation id={props.id} />
        </Section>
    );
};

export default AllocationContainer;
