import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BlueprintProvider } from "@blueprintjs/core";
import dotenv from "dotenv";

import "./index.css";
import "./config";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

dotenv.config();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BlueprintProvider>
            <App />
        </BlueprintProvider>
    </React.StrictMode>
);

reportWebVitals(console.log);
